"use client";

import { Dispatch, Fragment, SetStateAction, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { RedirectButton } from '../redirect-button/redirect-button'
import Link from 'next/link';
import { IIdpSearch } from '@stud/shared/interfaces';

export interface EntityModalProps {
  dialogOpen: boolean
  setDialogOpen: Dispatch<SetStateAction<boolean>>
  isRedirecting: boolean
  setIsRedirecting: Dispatch<SetStateAction<boolean>>
  entityData: IIdpSearch
}

export function EntityModal(props: EntityModalProps) {

  const cancelButtonRef = useRef(null)
  return (
    <Transition.Root show={props.dialogOpen} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto block sm:hidden" initialFocus={cancelButtonRef} onClose={() => props.setDialogOpen(false)}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            {props.entityData && props.entityData &&
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all w-full">
                <div>
                  <div className="sm:block absolute top-0 right-0 pt-4 pr-4">
                    <button
                      type="button"
                      className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={() => props.setDialogOpen(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="mx-auto flex items-center justify-center p-6">
                    {props.entityData.logo && (
                      <img
                        src={props.entityData.logo.url}
                        alt=""
                        className="max-h-16"
                      />
                    )}
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-semibold text-gray-900">
                      {props.entityData.displayName}
                    </Dialog.Title>
                    <div className="text-left flex-none flex-col divide-y  divide-gray-100 overflow-y-auto sm:flex z-10">
                      <div className="flex flex-auto flex-col justify-between p-6">
                        <dl className="grid grid-cols-1 gap-x-6 gap-y-3 text-sm text-gray-700">
                          <dt className="col-end-1 font-semibold text-gray-900">Country</dt>
                          <dd>{props.entityData.country}</dd>
                          {props.entityData.informationUrl && (
                            <>
                              <dt className="col-end-1 font-semibold text-gray-900">Website</dt>
                              <dd className="truncate">
                                <Link
                                  href={props.entityData.informationUrl as string}
                                  target="_blank"
                                  className="text-indigo-600 underline"
                                  rel="noreferrer"
                                >
                                  {props.entityData.informationUrl}
                                </Link>
                              </dd>
                            </>
                          )}
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <RedirectButton isRedirecting={props.isRedirecting} setIsRedirecting={props.setIsRedirecting} entityData={props.entityData} />
                </div>
              </div>
            }
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
